<template>
  <div>
    <div class="mvr-wrap">
      <!-- Content Header (Page header) -->
      <div class="content-header" style="">
        <div class="container-fluid">
          <div class="row mb-2">
            <custom-alert v-if="displayAlert==true" id="alert_box" :message="alertMessage" v-on:showedAlert="resetAlert"></custom-alert>
            <div class="col-sm-6">
              <h1 class="m-0 text-dark">{{ getSiteProps('general.site_name') }}</h1>
            </div>         
            <div class="col-sm-6"></div>           
          </div>       
        </div>    
        <div class="row rm-p-m" v-if="loading">
            <div class="col-md-12 text-center p-3">
                <i class="fa fa-circle-notch fa-spin fa-2x"></i>
            </div>
        </div>
        <div class="row rm-p-m" v-else>
          <div class="col-md-12" v-if="fees.fee">
            <div class="clubrenewal" v-if="profileData.info.background_mvr_review == null">
              <div class="tabbable">
                <div class="h-steps ">
                  <section v-if="wiz_step == 1">
                    <ul class="header-navigation">
                      <li>
                        <span class="header-steps current-item">Welcome</span>
                      </li>
                      <li>
                        <span class="header-steps">Application Information</span>
                      </li> 
                      <li><span class="header-steps">Sign</span></li>
                      <li v-show="groupCommittee.payment == true"><span class="header-steps">Pay</span></li>
                      <li><span class="header-steps">Thank You</span></li>
                    </ul>
                  </section>
                  <section v-if="wiz_step == 2">
                    <ul class="header-navigation">
                      <li>
                        <span class="header-steps completed">Welcome</span>
                      </li>
                      <li>
                        <span class="header-steps current-item"
                          >Applicant Information</span
                        >
                      </li>
                      <li><span class="header-steps">Sign</span></li>
                      <li v-show="groupCommittee.payment == true"><span class="header-steps">Pay</span></li>
                      <li><span class="header-steps">Thank You</span></li>
                    </ul>
                  </section>
                  <section v-if="wiz_step == 3">
                    <ul class="header-navigation">
                      <li>
                        <span class="header-steps completed">Welcome</span>
                      </li>
                      <li>
                        <span class="header-steps completed"
                          >Applicant Information</span
                        >
                      </li>
                      <li><span class="header-steps  current-item">Sign</span></li>
                      <li v-show="groupCommittee.payment == true"><span class="header-steps">Pay</span></li>
                      <li>
                        <span class="header-steps">Thank You</span>
                      </li>
                    </ul>
                  </section>
                  <section v-if="wiz_step == 4">
                    <ul class="header-navigation">
                      <li>
                        <span class="header-steps completed">Welcome</span>
                      </li>
                      <li>
                        <span class="header-steps completed"
                          >Applicant Information</span
                        >
                      </li>
                      <li><span class="header-steps current-item">Sign</span></li>
                      <li v-show="groupCommittee.payment == true"><span class="header-steps">Pay</span></li>
                      <li>
                        <span class="header-steps">Thank You</span>
                      </li>
                    </ul>
                  </section>
                  <section v-if="wiz_step == 5">
                    <ul class="header-navigation">
                      <li>
                        <span class="header-steps completed">Welcome</span>
                      </li>
                      <li>
                        <span class="header-steps completed">Applicant Information</span>
                      </li>
                      <li><span class="header-steps completed">Sign</span></li>
                      <li v-show="groupCommittee.payment == true"><span class="header-steps current-item">Pay</span></li>
                      <li>
                        <span class="header-steps">Thank You</span>
                      </li>
                    </ul>
                  </section>
                  <section v-if="wiz_step == 6">
                    <ul class="header-navigation">
                      <li>
                        <span class="header-steps completed">Welcome</span>
                      </li>
                      <li>
                        <span class="header-steps completed">Applicant Information</span>
                      </li>
                      <li><span class="header-steps completed">Sign</span></li>
                      <li v-show="groupCommittee.payment == true"><span class="header-steps completed">Pay</span></li>
                      <li>
                        <span class="header-steps current-item">Thank You</span>
                      </li>
                    </ul>
                  </section>
                </div>
              </div>
            </div>
            <div v-if="groupCommittee.screening == true && backgroundScreen == null && memberAge >= 16">
              <div v-if="wiz_step == 1" class="donation-view">
                <section>
                  <div class="card donation">
                    <div class="card-header register-title">
                      <h3>Welcome</h3>
                    </div>
                    <div class="card-body">
                      <div class="row">
                        <div class="col-md-12">
                          <p>
                            Thank you for your interest in USA Water Ski and Wake Sports!
                          </p>
                          <p>
                            USA Water Ski & Wake Sports has established the following criteria for exclusion of participation:
                          </p>
                          <ul class="grassrootul backscreen-group">
                            <li>
                              <span class="font-13 align-items-baseline"
                                ><i class="fas fa-chevron-right"></i
                                ><span
                                  >Conviction of a major driving offense in the past three (3) years including, but not limited to:
                                </span></span>
                                <ul class="welcomeulsub">
                                      <li>DUI/DWI (either a motor vehicle or vessel/watercraft)</li>
                                      <li>Possession of an open container while operating a motor vehicle or vessel/watercraft</li>
                                      <li>Any drug-related motor vehicle incident or vessel/watercraft incident.</li>
                                      <li>Leaving the scene of a motor vehicle or vessel/watercraft accident</li>
                                      <li>Assault (in any form) by use of a motor vehicle or vessel/watercraft</li>
                                      <li>Reckless driving of a motor vehicle or vessel/watercraft (willful or wanton disregard for the safety of persons or property in any form)</li>
                                    </ul>
                            </li>
                            <li>
                              <span class="font-13 align-items-baseline"
                                ><i class="fas fa-chevron-right"></i
                                ><span
                                  >Motor vehicle Driver's license or any applicable vessel/watercraft operator's license is currently suspended, or has been suspended within the past three (3) years for any major driving offense (per criteria number 1, above). Three (3) years from the end of the suspension date.
                                </span></span
                              >
                            </li>
                            <li>
                              <span class="font-13 align-items-baseline"
                                ><i class="fas fa-chevron-right"></i
                                ><span
                                  >Motor vehicle driver's license or any applicable vessel/watercraft operator's license is currently suspended for any other reason (but with immediate reinstatement being allowed upon driver's license restoration).
                                </span></span
                              >
                            </li>
                          </ul>
                          <!-- <p>Have you already started? Pick up where you left off.</p>
                          <button type="submit" class="btn btn-outline-primary savebtn next-step-btn"> Resume Application</button> -->
                          <h4> Other Questions</h4>
                          <p>NCSI is the official motor vehicle record screening provider for USA Water Ski & Wake Sports. If you would like to learn more about NCSI, please visit their  website at <a href="https://solutions.ncsisafe.com" target="_blank">https://solutions.ncsisafe.com</a>.</p>
                          <p><b>Canadian applicants</b> send an email to <a href="mailto:usawaterski@usawaterski.org">usawaterski@usawaterski.org</a> to request a customized link to complete their application and purchase the MVR Review directly through NCSI</p>
                          <p>For questions about this application process, you may contact support@ncsisafe.com.</p>
                          <p>To begin your motor vehicle record  screening application, please click NEXT.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
              <div v-if="wiz_step == 2" class="merchandise-view">
                <div class="row">       
                  <div class="col-md-12">
                        <div class="card donation">
                            <div class="card-header register-title">
                            <h3>Applicant Information</h3>
                            </div>
                            <div class="card-body">
                                <p>NOTE FOR PENNSYLVANIA DRIVER'S LICENSEES ONLY:</p>
                                <p>Please provide your SSN below for the MVR screening as a requirement to receive expedited processing with the State of Pennsylvania DOT.</p>
                                <p>TO AVOID DELAYS IN THE RETURN OF YOUR MOTOR VEHICLE RECORD SCREENING RESULTS, PLEASE MAKE SURE YOU:</p>
                            <ul class="grassrootul backscreen-group">
                            <li>
                              <span class="font-13 align-items-baseline"
                                ><i class="fas fa-chevron-right"></i
                                ><span>Enter your LEGAL name as it appears on your current valid government-issued ID.</span></span></li>
                                <li><span class="font-13 align-items-baseline"
                                ><i class="fas fa-chevron-right"></i
                                ><span>DO NOT use nicknames (ex. Rob for Robert, Sue for Susan).</span></span></li>
                                <li><span class="font-13 align-items-baseline"
                                ><i class="fas fa-chevron-right"></i
                                ><span>Double-check your entry for accuracy before continuing with your submission.</span></span></li>
                                <li><span class="font-13 align-items-baseline"
                                ><i class="fas fa-chevron-right"></i
                                ><span>The application will accept only the two-letter state abbreviation in the ST field (ex. CO for Colorado).</span></span></li>
                                </ul>
                            <div class="row">
                                <div class="col-md-3">
                                    <div class="form-group">
                                        <label for="firstName" class="form-label"> First Name<sup class="text-danger">*</sup></label>
                                        <input type="text" class="form-control" v-model="form.first_name">
                                        <span class="text-sm text-danger">{{ errors.first_name}}</span>
                                    </div>

                                </div>
                                <div class="col-md-3">
                                    <div class="form-group">
                                    <label for="middleinitial" class="form-label">Middle Initial</label>
                                    <input type="text" class="form-control" v-model="form.middle_name">
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="form-group">
                                    <label for="lastname" class="form-label">Last Name<sup class="text-danger">*</sup></label>
                                    <input type="text" class="form-control" v-model="form.last_name">
                                      <span class="text-sm text-danger">{{ errors.last_name}}</span>
                                    </div>
                                </div>
                              <div class="col-md-3">
                                <div class="form-group birthdate">
                                  <label for="lastname" class="form-label">DOB<sup class="text-danger">*</sup></label>
                                  <date-pick
                                      v-model="form.dob"
                                      :value="form.dob | formatDob"
                                      :pickTime="false"
                                      :selectableYearRange="{from: 1900, to: 2021}"
                                      name="form.dob"
                                      :format="'MM/DD/YYYY'"
                                      v-mask="'##/##/####'"
                                      :isDateDisabled="isFutureDate"
                                      placeholder="MM/DD/YYYY"
                                      :parseDate="parseDatePick"
                                  ></date-pick>
                                  <span class="text-sm text-danger">{{ errors.dob}}</span>
                                </div>
                              </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                   <label for="lastname" class="form-label">Email<sup class="text-danger">*</sup></label>
                                    <input type="email" class="form-control" v-model="form.email">
                                      <span class="text-sm text-danger">{{ errors.email}}</span>
                                    </div>
                                </div>
                                <div class="col-md-2">
                                    <div class="form-group">
                                      <label for="lastname" class="form-label">Driver's License<sup class="text-danger">*</sup></label>
                                      <input type="text" class="form-control license" v-model="form.driverlicense" placeholder="Driver's License">
                                      <span class="text-sm text-danger">{{ errors.driverlicense}}</span>                               
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="form-group">
                                      <label for="driverlicense_extension" class="form-label">DL State<sup class="text-danger">*</sup></label>
                                      <!-- <input type="text" class="form-control ext" v-model="form.driverlicense_extension" placeholder="ST" @keypress="isAlphabets(event)"> -->
                                      <select class="form-control" v-model="form.driverlicense_extension" name="form.driverlicense_extension">
                                          <option value="">Select</option>
                                          <option  v-for="listOfState in listOfStates" :key="listOfState.state_code" :value="listOfState.state_code">{{ listOfState.state }}</option>
                                      </select>
                                      <span class="text-sm text-danger">{{ errors.driverlicense_extension}}</span>                                 
                                    </div>
                                </div>
                                <div class="col-md-4" v-if="form.driverlicense_extension == 'pa' || form.driverlicense_extension == 'PA'">
                                     <div class="form-group">
                                    <label for="lastname" class="form-label">SSN<sup class="text-danger">*</sup></label>
                                    <input type="text" class="form-control" v-model="form.ssn" @keypress="onlyNumber">
                                       <span class="text-sm text-danger">{{ errors.ssn}}</span>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                     <div class="form-group">
                                    <!-- <label for="lastname" class="form-label">Phone<sup class="text-danger">*</sup></label>
                                    <input type="text" class="form-control" v-model="form.phone">
                                       <span class="text-sm text-danger">{{ errors.phone}}</span> -->
                                        <div class="phone-nu-wrapper">
                                        <label for="phone" class="form-label">Phone</label>
                                        <vue-tel-input v-model="form.phone" ref="phone" maxlength="20" v-bind="mobileProps"  @validate="validateTelinput"  :validCharactersOnly="true"></vue-tel-input>
                                        <span class="text-sm text-danger">{{ errors.phone }}</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                     <div class="form-group">                                    
                                     <p class="font-13">To avoid delays: please provide your Mother's maiden name.</p>
                                    <input type="text" class="form-control" v-model="form.mother_maiden_name">
                                    </div>
                                </div>
                                <hr>
                                <div class="col-md-12">
                                    <h4> Address History</h4>
                                    <p>The application will accept only the two-letter state abbreviation in the ST field (ex. CO for Colorado).</p>

                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                    <label for="lastname" class="form-label">Street<sup class="text-danger">*</sup></label>
                                    <input type="text" class="form-control" v-model="form.street1">
                                      <span class="text-sm text-danger">{{ errors.street1}}</span>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                    <label for="lastname" class="form-label">City<sup class="text-danger">*</sup></label>
                                    <input type="text" class="form-control" v-model="form.city">
                                      <span class="text-sm text-danger">{{ errors.city}}</span>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                    <label for="lastname" class="form-label">State/Province<sup class="text-danger">*</sup></label>
                                    <select class="form-control" id="state" v-model="form.state" name="form.state_code" v-if="form.country == 'USA'">
                                    <option value="">Select</option>
                                    <option  v-for="listOfState in listOfStates" :key="listOfState.state_code" :value="listOfState.state_code">{{ listOfState.state }}</option>
                                    </select>
                                    <select class="form-control" id="state" v-model="form.state" name="form.state" v-else-if="form.country == 'Canada'">
                                    <option value="">Select</option>
                                    <option  v-for="listOfState in listOfCAStates" :key="listOfState.state_code" :value="listOfState.state">{{ listOfState.state }}</option>
                                    </select>
                                    <input type="text" v-else class="form-control" v-model="form.state" name="form.state_code">
                                      <span class="text-sm text-danger">{{ errors.state}}</span>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                    <label for="lastname" class="form-label">Zip Code<sup class="text-danger">*</sup></label>
                                    <input type="text" class="form-control" v-model="form.zip">
                                      <span class="text-sm text-danger">{{ errors.zip}}</span>
                                    </div>
                                </div>      
                                <div class="col-md-4">
                                  <div class="form-group">
                                     <label for="lastname" class="form-label">Country<sup class="text-danger">*</sup></label>
                                     <select class="form-control" id="country" @change="countryChange" v-model="form.country" name="form.country">
                                      <option value="">Select</option>
                                      <option :value="listOfCountry.country" v-for="listOfCountry in listOfCountries" :key="listOfCountry.country" :selected="listOfCountry.country == 'Bahamas'">{{ listOfCountry.country }}</option>
                                      </select>
                                      <input type="hidden" name="form.country_code" v-model="form.country_code" />
                                      <span class="text-sm text-danger">{{ errors.country}}</span>
                                  </div>
                                </div>     
                            </div>
                            </div>
                        </div>
                    </div>
                   </div>
              </div>
              <div v-if="wiz_step == 3">
                <div class="row">
                  <div class="col-md-12">
                    <div class="card-body card">
                      <div class="card-header register-title">
                        <h3>State and City Notices</h3>
                      </div>
                      <div class="row">
                        <div class="col-md-6 mt-3">
                          <label for="lastname" class="form-label">I certify that I am an individual seeking prospective
                            or continued participation in:<sup class="text-danger">*</sup></label>
                          <select id="select-team" v-model="form.state_specific_work" class="form-control">
                            <option value="">Select</option>
                            <option v-for="(hstate, index) in listOfStates" :value="hstate.state_code"
                                    :key="'types'+index">{{ hstate.state }}
                            </option>
                          </select>
                          <span class="text-sm text-danger">{{ errors.state_specific_work }}</span>
                          <div class="col-md-12 mt-3" v-if="form.state_specific_work == 'CA'">
                          <div class="form-group mt-2">
                            <label>Are you an individual seeking prospective or continued employment to work in San
                              Francisco, California?</label>
                            <select class="form-control" v-model="form.seeking_prospective_first">
                              <option value="" >Please Select</option>
                              <option value="Yes">Yes</option>
                              <option value="No">No</option>
                            </select>
                            <span class="text-sm text-danger">{{ errors.seeking_prospective_first }}</span>
                          </div>
                        </div>
                        <div class="col-md-12 mt-3" v-if="form.state_specific_work == 'CA'">
                          <div class="form-group mt-2">
                            <label>Are you an individual seeking prospective or continued employment to work in Los
                              Angeles, California?</label>
                            <select class="form-control" v-model="form.seeking_prospective_second">
                              <option value="" >Please Select</option>
                              <option value="Yes">Yes</option>
                              <option value="No">No</option>
                            </select>
                            <span class="text-sm text-danger">{{ errors.seeking_prospective_second }}</span>
                          </div>
                        </div>
                        </div>                        
                        <div class="col-md-6 mt-3">
                          <label for="lastname" class="form-label">I am a resident of:<sup
                              class="text-danger">*</sup></label>
                          <select id="select-team" v-model="form.state_specific_home" class="form-control">
                            <option value="">Select</option>
                            <option v-for="(lstate, index) in listOfStates" :value="lstate.state_code"
                                    :key="'types-home'+index">{{ lstate.state }}
                            </option>
                          </select>
                          <span class="text-sm text-danger">{{ errors.state_specific_home }}</span>
                          <div class="col-md-12 mt-3" v-if="form.state_specific_home == 'CA'">
                          <div class="form-group mt-2">
                            <label>Are you a resident of San
                              Francisco, California?</label>
                            <select class="form-control" v-model="form.resident_first">
                              <option value="">Please Select</option>
                              <option value="Yes">Yes</option>
                              <option value="No">No</option>
                            </select>
                            <span class="text-sm text-danger">{{ errors.resident_first }}</span>
                          </div>
                        </div>
                        <div class="col-md-12 mt-3" v-if="form.state_specific_home == 'CA'">
                          <div class="form-group mt-2">
                            <label>Are you a resident of Los Angeles, California?</label>
                            <select class="form-control" v-model="form.resident_second">
                              <option value="">Please Select</option>
                              <option value="Yes">Yes</option>
                              <option value="No">No</option>
                            </select>
                            <span class="text-sm text-danger">{{ errors.resident_second }}</span>
                          </div>
                        </div>
                        </div>                        
                      </div>
                    </div>
                  </div>
                  </div>
              </div>
              <div v-if="wiz_step == 4">
                <div class="row">
                  <div class="col-md-12">
                    <div class="card p-5">
                      <div class="card-header register-title d-none">
                        <h3 v-if="form.state_specific_home == 'CA'"> SIGNATURE ({{ waiver_index > 4?waiver_index-1: waiver_index+1 }} OF {{ 7 }})</h3>
                        <h3 v-else-if="form.state_specific_home == 'NY'"> SIGNATURE ({{ waiver_index > 5?5: waiver_index+1 }} OF {{ 5 }})</h3>
                        <h3 v-else> SIGNATURE ({{ waiver_index > 4?4: waiver_index+1 }} OF {{ 4 }})</h3>
                      </div>
                     <div v-show="waiver_index == 0" class="card card-body">
                      <div class="register-title"><h3>ELECTRONIC SIGNATURE CONSENT</h3></div> 
                     <p>As part of the selection process at USA Water Ski & Wake Sports, the "Company," you will need to consent to a motor vehicle record  check electronically. By typing your name, you are consenting to receive any communications (legally required or otherwise) and all changes to such communications electronically. In order to use the website, you must provide at your own expense an Internet connected device that is compatible with the minimum requirements outlined below. You also confirm that your device will meet these specifications and requirements and will permit you to access and retain the communications electronically each time you access and use the website.</p>
                    <p><strong>System Requirements to Access Information</strong></p>
                     To receive and view an electronic copy of the Communications you must have the following equipment and software:
                     <ul class="listitems-ul">
                       <li>
                         A personal computer or other device which is capable of accessing the Internet. Your access to this page verifies that your system/device meets these requirements.
                       </li>
                       <li>
                         A current version of Chrome, Firefox, Safari, Internet Explorer, or Microsoft Edge Internet web browser which supports security industry best practices for HTTPS encrypted communications, JavaScript, and cookies. Your access to this page verifies that your browser meets these requirements.
                       </li>
                     </ul>
                     <p><strong>System Requirements to Retain Information</strong></p>
                     <p>To retain a copy, you must either have a printer connected to your personal computer or other device or, alternatively, the ability to save a copy through use of printing service or software such as Adobe Acrobat®.</p>
                     <p><strong>Withdrawal of Electronic Acceptance of Disclosures and Notices</strong></p>
                     <p>You can also contact NCSI to withdraw your consent to receive any future communications electronically, including if the system requirements described above change and you no longer possess the required system. If you withdraw your consent, we will terminate your use of the National Center for Safety Initiatives, LLC (NCSI) website and the services provided through the National Center for Safety Initiatives, LLC (NCSI) website.</p>
                     <p>To ensure that a signature is unique and to safeguard you against unauthorized use of your name, your IP address {{ IpAddressVal }} has been recorded and will be stored along with your electronic signature. Please note that if you wish to submit your Disclosure and Authorization Forms electronically, National Center for Safety Initiatives, LLC (NCSI) requires that you include your social security number or user identification. All of your information will be encrypted and transmitted via our secure website.</p>
                     <div class="card p-4">
                       <p>By typing my name below, I consent to transacting electronically, including receiving legally required notices electronically. I understand that uses computer technology to ensure that my signed documents are not altered after submission. I agree to allow to validate my signed documents in this way.</p>
                       <input type="text" class="form-control" placeholder="Type Full Name" v-model="waiverSign[waiver_index].name">
                       <span class="text-sm text-danger">{{ errors.name}}</span>
                     </div>
                     </div>
                     <div v-show="waiver_index == 1" class="card card-body">
                       <div class="register-title"><h3>Disclosure Regarding Background Investigation</h3></div>
                       <p>USA Water Ski & Wake Sports (the “Company”) may obtain information about you from a third party consumer
                         reporting agency for employment purposes. Thus, you may be the subject of a “consumer report”
                         which may include information about your character, general reputation, personal characteristics,
                         and/or mode of living. These reports may contain information regarding your
                         criminal history, social security verification, motor vehicle records (“driving records”), verification
                         of your education or employment history, or other background checks.</p>
                       <p>The investigations will be conducted by <b>National Center for Safety Initiatives, LLC (NCSI); P.O. Box 39008 Cleveland, Ohio 44139; tel. # 866-996-7412;</b> <a href="www.solutions.ncsisafe.com" target="_blank">www.solutions.ncsisafe.com.</a></p>
                       <div class="background-gray card p-4">
                         <p>By typing my name below, I acknowledge receipt of the DISCLOSURE REGARDING BACKGROUND INVESTIGATION and certify that I have read and understand this document.</p>
                         <div class="row">
                           <div class="col-md-10">
                             <input type="text" class="form-control" placeholder="Type Full Name" v-model="waiverSign[waiver_index].name">
                             <span class="text-sm text-danger">{{ errors.name}}</span>
                           </div>
                           <div class="col-md-2">
                             <p>Date: {{ currentDate }}</p>
                           </div>
                         </div>
                       </div>
                     </div>
                      <div v-show="waiver_index == 2" class="card card-body">

                      <img src="\dist\img\profile\backimg\FCRA\0001.jpg">
                      <img src="\dist\img\profile\backimg\FCRA\0002.jpg">
                      <img src="\dist\img\profile\backimg\FCRA\0003.jpg">
                      <img src="\dist\img\profile\backimg\FCRA\0004-2024.jpg">
                      <div class="card p-4">
                        By typing my name below, I acknowledge receipt of the Summary of Your Rights Under the Fair Credit Reporting Act (FCRA) and certify that I have read and understand this document.
                        <input type="text" class="form-control"  placeholder="Type Full Name" v-model="waiverSign[waiver_index].name">
                          <span class="text-sm text-danger">{{ errors.name}}</span>
                      </div>

                      </div>
                      <div v-show="waiver_index == 3" class="card card-body">
                        <img src="/dist/img/profile/backimg/NY-Article-23A.jpg" v-if="form.state_specific_home =='NY' || form.state_specific_work == 'NY'"/>
                        <template v-else>
                          <table border="1" class="other_state_rules">
                            <tbody>
                            <tr>
                              <td>
                                <b>New York residents and applicants only:</b> Upon request, you will be informed whether or
                                not a consumer report was requested by the Company, and if such report was requested,
                                informed of the name and address of the consumer reporting agency that furnished the
                                report. You have the right to inspect and receive a copy of any investigative consumer
                                report requested by the Company by contacting the consumer reporting agency directly.
                                By signing the Acknowledgement and Authorization for Background Investigation,
                                you acknowledge receipt of Article 23-A of the New York Correction Law.
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <b>New York City residents and applicants only:</b> By signing the Acknowledgement and
                                Authorization for Background Investigation, you further authorize the Company to
                                provide you with a copy of your consumer report, the New York City Fair Chance Act
                                Notice form, and any other documents, to the extent required by law, at the mailing
                                address and/or email address you provide to the Company.
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <b>Minnesota residents and applicants only:</b> You have the right to submit a written request
                                to the consumer reporting agency for a complete and accurate disclosure of the nature
                                and scope of any consumer report the Company ordered about you. The consumer reporting
                                agency must provide you with this disclosure within five business days after its receipt
                                of your request or the report was requested by the Company, whichever date is later.
                                Please check this box if you would like to receive a copy of a consumer report if one is
                                obtained by the Company. <input type="checkbox" id="state_rule_minnesota_check" value="1" @change="waiverAdditionalDataChange">
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <b>Oklahoma residents and  applicants only:</b> Please check this box if you would like to
                                receive a copy of a consumer report if one is obtained by the Company.
                                <input type="checkbox" id="state_rule_oklahoma_check" value="1" @change="waiverAdditionalDataChange">
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <b>Los Angeles residents and applicants only:</b> Please <a href="https://bca.lacity.org/Uploads/fciho/Ban%20the%20Box%20Poster%20-%20Private%20Employers%2C%20as%20of%208.23.18.pdf" target="_blank">click here</a>
                                to receive a copy of the <u>Notice to Applicants & Employees Fair Chance Initiative for
                                Hiring Ordinance.</u>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <b>San Francisco residents and applicants only:</b> Please <a href="https://sfgov.org/olse/sites/default/files/FCO%20poster2020.pdf">click here</a> to receive a copy of the
                                <u>San Francisco Fair Chance Ordinance Notice.</u>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <b>Washington State residents and applicants only:</b> You have the right to request from the
                                consumer reporting agency a written summary of your rights and remedies under the
                                Washington Fair Credit Reporting Act.
                              </td>
                            </tr>
                            </tbody>
                          </table>
                        </template>

                        <template v-if="form.state_specific_home =='NY' || form.state_specific_work == 'NY'">
                          <h1>NEW YORK CITY APPLICANTS ONLY</h1>
                          <p>By clicking the box below, you further authorize USA Water Ski &amp; Wake Sports ("the Company") to provide you with a copy of your consumer report, the New York City Fair Chance Act Notice form, and any other documents, to the extent required by law, at the mailing address and/or email address you provide to the Company.</p>
                        </template>
                        <div class="background-gray">
                          <div class="p-3">
                            <label for="iunderstand" class="form-check-label"
                            >I <input
                                type="checkbox"
                                id="iunderstand"
                                class="form-check-input" v-model="waiverSign[waiver_index].agree_first"
                            />
                              <template v-if="form.state_specific_home =='NY' || form.state_specific_work == 'NY'">
                                acknowledge receipt of the NEW YORK CORRECTION LAW ARTICLE 23-A, certify that I have read and understand these documents.
                              </template>
                              <template v-else>
                                acknowledge receipt of the Other States Notice, certify that I have read and understand these documents.
                              </template>
                            </label>
                            <span class="text-sm text-danger"><br>{{ errors.agree_first}}</span>
                          </div>
                        </div>
                      </div>
                      <div v-show="waiver_index == 4 && (form.state_specific_home =='CA' || form.state_specific_work == 'CA')" class="card card-body">
                      <img src="/dist/img/profile/backimg/0001.jpg">                      
                       <img src="/dist/img/profile/backimg/0002.jpg" class="p-2">
                       <div class="card p-5">
                              <label for="iunderstand" class="form-check-label"
                        ><input type="checkbox" class="form-check-input" v-model="waiverSign[waiver_index].agree_first" /> I acknowledge receipt of the San Francisco Fair Chance Ordinance and certify that I have read and understand this document.
                        </label>
                          <span class="text-sm text-danger">{{ errors.agree_first}}</span>
                       </div>                   
                      </div>
                      <div v-show="waiver_index == 5 && (form.state_specific_home =='CA' || form.state_specific_work == 'CA')" class="card card-body">
                       <img src="/dist/img/profile/backimg/los-1.jpg">                      
                       <img src="/dist/img/profile/backimg/los-2.jpg">
                        <div class="card p-5">
                        <label for="iunderstand" class="form-check-label"
                        ><input type="checkbox" class="form-check-input" v-model="waiverSign[waiver_index].agree_first" /> I acknowledge receipt of the City of Los Angeles and certify that I have read and understand this document.</label>
                        <span class="text-sm text-danger">{{ errors.agree_first}}</span>
                        </div>
                      </div>
                      <div v-show="waiver_index == 6 && (form.state_specific_home =='CA' || form.state_specific_work == 'CA')" class="card card-body">
                        <div class="register-title"><h3>NOTICE REGARDING BACKGROUND CHECKS PER CALIFORNIA LAW</h3></div>
                        <img src="/dist/img/profile/backimg/USAWSWS-disclosure-CA.png">
                        <div class="card p-5">
                          <label for="iunderstand" class="form-check-label"><input type="checkbox" class="form-check-input" v-model="waiverSign[waiver_index].agree_first" />Please check this box if you would like to receive a copy of an investigative consumer report or consumer credit report at no charge if one is obtained by the Company whenever you have a right to receive such a copy under California law.</label>
                          <span class="text-sm text-danger">{{ errors.agree_first}}</span>
                          <label for="iunderstand" class="form-check-label"><input type="checkbox" class="form-check-input" v-model="waiverSign[waiver_index].agree_second" />I acknowledge receipt of the NOTICE REGARDING BACKGROUND CHECKS PER CALIFORNIA LAW and certify that I have read and understand this document.</label>
                          <span class="text-sm text-danger">{{ errors.agree_second}}</span>
                        </div>
                      </div>
                      <div v-show="waiver_index == 7" class="card card-body">
                        <div class="register-title"><h3>ACKNOWLEDGMENT AND AUTHORIZATION FOR BACKGROUND INVESTIGATION</h3></div>
                        <p>I acknowledge receipt of the separate documents entitled DISCLOSURE REGARDING BACKGROUND INVESTIGATION, DISCLOSURE FOR INVESTIGATIVE CONSUMER REPORT (if applicable), A SUMMARY OF YOUR RIGHTS UNDER THE FAIR CREDIT REPORTING ACT and OTHER STATE LAW NOTICES and certify that I have read and understand those documents. I hereby authorize the obtaining of “consumer reports” and/or “investigative consumer reports” by <b>USA Water Ski & Wake Sports</b> (the “Company“) at any time after receipt of this authorization and throughout my employment, if applicable. To this end, I hereby authorize, without reservation, any law enforcement agency, administrator, state or federal agency, institution, school or university (public or private), information service bureau, employer, or insurance company to furnish any and all background information requested by <b>National Center for Safety Initiatives, LLC (NCSI); P.O. Box 39008 Cleveland, Ohio 44139; tel. # 866-996-7412;</b> <a href="www.solutions.ncsisafe.com">www.solutions.ncsisafe.com</a> and/or the Company. I agree that a facsimile (“fax”), electronic or photographic copy of this Authorization shall be as valid as the original.</p>
                        <div class="pl-3 pt-3 pr-3 pb-3">
                          <label for="iunderstand" class="form-check-label"><input type="checkbox" class="form-check-input" v-model="waiverSign[waiver_index].agree_first" />Please check this box to receive from NCSI; a copy of any report furnished by NCSI to the Company pursuant to your authorization.</label>
                          <br><span class="text-sm text-danger">{{ errors.agree_first}}</span>
                        </div>                        
                        <div class="background-gray gray">
                        <div class="row">
                        <div class="col-md-4">
                        <label>Name</label> 
                        <input type="text" class="form-control" placeholder="Type Full Name" v-model="waiverSign[waiver_index].name">
                          <span class="text-sm text-danger">{{ errors.name}}</span>
                        </div>
                        <div class="col-md-4">
                        <label>ID/SSN (last 4)</label> 
                        <input type="text" class="form-control" placeholder="Last 4 Digit" v-model="waiverSign[waiver_index].ssn">
                          <span class="text-sm text-danger">{{ errors.ssn}}</span>
                        </div>
                        <div class="col-md-4 mt-4">
                        <label>Ip Address:</label> 
                        {{ IpAddressVal }}
                        </div>
                        </div>
                        </div>
                      </div>
                      <!--no proper flow -->
                      <div v-show="waiver_index == 8" class="card card-body">
                       <div class="register-title"><h3>Additional Screening Consent</h3></div>
                        <p>
                          I acknowledge receipt of the separate document entitled DISCLOSURE REGARDING
                          BACKGROUND SEARCH and A SUMMARY OF YOUR RIGHTS UNDER THE FAIR
                          CREDIT REPORTING ACT and certify that I have read and understand both of those
                          documents. I hereby authorize an additional background search within 24 months of
                          my initial search authorization by the obtaining of &quot;consumer reports&quot; and/or
                          &quot;investigative consumer reports&quot; by {{ getSiteProps("general.site_name") }} (&quot;Company&quot;) at any time after
                          receipt of this authorization so long as employment or participation continues as coach,
                          volunteer, staff, board member, official or other authorized representative position
                          (Position) within the Company, if applicable. To this end, I hereby authorize, without
                          reservation, any law enforcement agency, administrator, state, municipal or federal
                          agency, institution, school or university (public or private), information service bureau,
                          employer, or insurance company to furnish any and all background information and
                          applicable records requested by <b>National Center for Safety Initiatives LLC (&quot;NCSI&quot;),
                          P.O. Box 39008, Cleveland, OH 44139; tel. # 866-996-7412; <a href="www.ncsisafe.com">www.ncsisafe.com</a> and
                          their agents,</b> and/or Company. I agree that a facsimile (&quot;fax&quot;), electronic or
                          photographic copy of this Authorization shall be as valid as the original. I understand
                          that I will have an opportunity to review the records as received by {{ getSiteProps("general.site_name") }} and a
                          procedure is available if I dispute the records that have been received.
                        </p>
                        <div class="row">
                          <div class="col-md-12">
                            <label>Name<sup class="text-danger">*</sup></label>
                            <input type="text" class="form-control" placeholder="Type Full Name" v-model="waiverSign[waiver_index].name">
                            <span class="text-sm text-danger">{{ errors.name  }}</span>
                          </div>
                        </div>
                     </div>
                      <div v-show="waiver_index == 9" class="card card-body">
                        <img src="\dist\img\profile\backimg\disclosure-inv-consumer-USAWS.png" alt="" v-if="subdomain === 'waterski'">
                        <img src="\dist\img\profile\backimg\disclosure-inv-consumer-USAS.png" alt="" v-if="subdomain === 'shooting'">
                        <div class="background-gray card p-4">
                          <p>By typing my name below, I acknowledge receipt of the DISCLOSURE REGARDING
                            “INVESTIGATIVE CONSUMER REPORT” and certify that I have read and understand this document.</p>
                          <div class="row">
                            <div class="col-md-10">
                              <input type="text" class="form-control" placeholder="Type Full Name" v-model="waiverSign[waiver_index].name">
                              <span class="text-sm text-danger">{{ errors.name}}</span>
                            </div>
                            <div class="col-md-2">
                              <p>Date: {{ currentDate }}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                  </div>
                  </div>
                </div>
              </div>
              <div v-if="wiz_step == 5">
                <div class="row payment-view">
                  <b-overlay :show="showLoader" no-wrap></b-overlay>
                  <div class="col-md-6 mb-view-pr mb-view-pl">
                    <div class="card-body card">
                      <div class="row">
                        <div class="col-md-12">
                          <h3>Total Amount</h3>
                        </div>
                        <div class="col-md-12">
                          <ul class="list-group mb-3 payment-details">
                            <li class="d-flex justify-content-between lh-sm">
                              <div>
                                <h6 class="my-0"><strong>MVR Review</strong></h6>
                              </div>
                              <span class="text-muted"><strong>${{ fees.fee }}</strong></span>
                            </li>
                            <div>
                              <li class=" d-flex justify-content-between  ">
                                <div class="coupon-inner mb-3">
                                  <h6 class="my-0"><strong>Coupon</strong></h6>
                                </div>
                                <div class="">
                                  <input type="text" class="form-control coupon-text" v-model="couponApplied.coupon_code"><button @click.prevent="applyCoupon()" class="btn-apply coupon-btn">{{ button.coupon }}</button>
                                  <span class="text-sm text-danger">{{ couponErrors.error}}</span>
                                  <span class="text-sm text-success">{{ couponErrors.success}}</span>
                                </div>
                              </li>
                              <li v-if="couponApplied.status == 1" class="d-flex justify-content-between lh-sm">
                                <div>
                                  <h6 class="my-0"><strong><a href="javascript:void(0);" class="text-danger" @click="removeCoupon()"><i class="fas fa-trash-alt"></i></a> Discount - {{ couponApplied.text }} </strong></h6>
                                </div>
                                <span class="text-muted"><strong>-${{ couponApplied.amount }}</strong></span>
                              </li>
                              <li class="d-flex justify-content-between lh-sm">
                                <div>
                                  <h6 class="my-0"><strong>Sub Total</strong></h6>
                                </div>
                                <span class="text-muted"><strong>${{ totals.sub_total }}</strong></span>
                              </li>
                              <li class=" d-flex justify-content-between lh-sm">
                                <div class="processing-tooltip">
                                  <h6 class="my-0"><strong>Technology/Processing Fee ({{ fees.processingFeePercent }}%)
                                    <b-button v-b-tooltip.hover
                                              title="This small fee enables USA-WSWS to make technology upgrades to enhance your membership experience.">
                                      <img v-bind:src="'/dist/img/memberregistration/tooltip-icon-small.png'"
                                           alt="tooltipicon"/></b-button>
                                  </strong></h6>
                                </div>
                                <span class="text-danger"><strong>${{ fees.processingFee }}</strong></span>
                              </li>
                            </div>
                            <li class=" d-flex justify-content-between lh-sm">
                              <div>
                                <h6 class="my-0"><strong>Grand Total</strong></h6>
                              </div>
                              <span class="text-danger"><strong>${{ fees.grand_total }}</strong></span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6 mb-view-pl mb-view-pr">
                    <div class="card-body card card-wrapper" v-if="paymentForm.visible">
                      <div class="row">
                        <div class="col-md-12 card-info">
                          <h3>Card Information</h3>
                          <div class="text-right">
                            <!--                                                                            <img v-bind:src="'/dist/img/memberregistration/stripe.png'" alt="stripe">-->
                          </div>
                        </div>
                        <div class="col-md-12">
                          <div class="form-group">
                            <label class="form-label">Card Number<sup class="text-danger">*</sup></label>
                            <input type="hidden" name="paymentForm.amount" v-model="paymentForm.amount">
                            <input type="hidden" name="paymentForm.registration_id" v-model="paymentForm.registration_id">
                            <input type="text" maxlength="16" placeholder="#### #### #### ####" class="form-control"
                                   name="paymentForm.cardNumber" v-model="paymentForm.cardNumber"
                                   @keypress="isNumber($event)">
                            <!--                                                                                <input class="form-control" name="paymentForm.cardNumber" v-model="paymentForm.cardNumber" v-cardformat:formatCardNumber>-->
                            <span class="text-sm text-danger">{{ errors.cardNumber }}</span>
                          </div>
                        </div>
                        <div class="col-md-5">
                          <label class="form-label">Expiration Date<sup class="text-danger">*</sup></label>
                          <select class="form-control form-select" id="expiration-month" required=""
                                  name="paymentForm.expiration_month" v-model="paymentForm.expiration_month">
                            <option value="">Month</option>
                            <option>01</option>
                            <option>02</option>
                            <option>03</option>
                            <option>04</option>
                            <option>05</option>
                            <option>06</option>
                            <option>07</option>
                            <option>08</option>
                            <option>09</option>
                            <option>10</option>
                            <option>11</option>
                            <option>12</option>
                          </select>
                          <span class="text-sm text-danger">{{ errors.expiration_month }}</span>
                        </div>
                        <div class="col-md-3 mp-l-r pl-0 pr-0 col-md-offset-1" style="margin-top: 30px;">
                          <select class="form-select form-control" id="expiration-year" required=""
                                  name="paymentForm.expiration_year" v-model="paymentForm.expiration_year">
                            <option value="">Year</option>
                            <option v-for="year in yearArr" :value="year" :key="year">{{ year }}</option>
                          </select>
                          <span class="text-sm text-danger">{{ errors.expiration_year }}</span>
                        </div>
                        <div class="col-md-3 classcvv">
                          <label class="form-label">CVV/CVC<sup class="text-danger">*</sup></label>
                          <input type="password" class="form-control" name="paymentForm.cvv" maxlength="4"
                                 v-model="paymentForm.cvv" @keypress="isNumber($event)">
                          <span class="text-sm text-danger">{{ errors.cvv }}</span>
                        </div>
                        <div class="col-md-12">
                          <div class="form-group">
                            <label class="form-label">Name on Card<sup class="text-danger">*</sup></label>
                            <input type="text" placeholder="William" class="form-control" id="billing_firstname"
                                   name="paymentForm.billing_firstname" v-model="paymentForm.billing_firstname">
                            <span class="text-sm text-danger">{{ errors.billing_firstname }}</span>
                          </div>
                        </div>
                        <hr/>
                        <div class="col-md-12">
                          <h3>Billing Address</h3>
                        </div>
                        <div class="col-md-12">
                          <div class="form-group">
                            <label>Mailing Address<sup class="text-danger">*</sup></label>
                            <input type="text" class="form-control" maxlength="45" name="paymentForm.billing_addressline1"
                                   v-model="paymentForm.billing_addressline1">
                            <span class="text-sm text-danger">{{ errors.billing_addressline1 }}</span>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label>City<sup class="text-danger">*</sup></label>
                            <input type="text" class="form-control" maxlength="45" name="paymentForm.billing_city"
                                   v-model="paymentForm.billing_city">
                            <span class="text-sm text-danger">{{ errors.billing_city }}</span>
                          </div>
                        </div>
                        <div class="col-md-6 pr-0" v-if="paymentForm.billing_country == 'USA'">
                          <div class="form-group">
                            <label>State<sup class="text-danger">*</sup></label>
                            <select class="form-control" id="state_code" name="paymentForm.billing_state_code"
                                    v-model="paymentForm.billing_state_code">
                              <option v-for="listOfState in listOfStates" :key="listOfState.state_code"
                                      :value="listOfState.state_code"
                                      :selected="paymentForm.billing_state_code == listOfState.state_code">
                                {{ listOfState.state }}
                              </option>
                            </select>
                            <span class="text-sm text-danger">{{ errors.billing_state_code }}</span>
                          </div>
                        </div>
                        <div class="col-md-6 pr-0" v-else-if="paymentForm.billing_country == 'Canada'">
                          <div class="form-group">
                            <label>State<sup class="text-danger">*</sup></label>
                            <select class="form-control" id="state_code" name="paymentForm.billing_state"
                                    v-model="paymentForm.billing_state">
                              <option v-for="listOfState in listOfCAStates" :key="listOfState.state_code"
                                      :value="listOfState.state"
                                      :selected="paymentForm.billing_state_code == listOfState.state_code">
                                {{ listOfState.state }}
                              </option>
                            </select>
                            <span class="text-sm text-danger">{{ errors.billing_state }}</span>
                          </div>
                        </div>
                        <div class="col-md-6" v-else>
                          <div class="form-group">
                            <label for="state_code" class="form-label">State <sup
                                class="text-danger">*</sup></label>
                            <input type="text" class="form-control" maxlength="45" v-model="paymentForm.billing_state"
                                   name="paymentForm.billing_state">
                            <span class="text-sm text-danger">{{ errors.billing_state }}</span>
                          </div>
                        </div>
                        <div class="col-md-6 pr-0">
                          <div class="form-group">
                            <label>Zip / Postal Code<sup class="text-danger">*</sup></label>
                            <input type="text" class="form-control" maxlength="15" name="paymentForm.billing_zip"
                                   v-model="paymentForm.billing_zip">
                            <span class="text-sm text-danger">{{ errors.billing_zip }}</span>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label>Country<sup class="text-danger">*</sup></label>
                            <select class="form-control" id="country" v-model="paymentForm.billing_country"
                                    name="paymentForm.billing_country">
                              <option v-for="listOfCountry in listOfCountries" :key="listOfCountry.country"
                                      :value="listOfCountry.country"
                                      :selected="paymentForm.billing_country == listOfCountry.country">
                                {{ listOfCountry.country }}
                              </option>
                            </select>
                            <span class="text-sm text-danger">{{ errors.billing_country }}</span>
                          </div>
                        </div>
                        <div class="col-md-12" v-if="this.getSiteProps('general.payment_fee_info')">
                          <!-- <p class="mt-3 font-13" style="font-size:11px;margin-bottom:0;"><i>All transactions also include a 3% processing/technology fee.</i></p> -->
                        </div>
                        <hr/>
                        <div class="col-md-12 payment-conf confirmsc">
                            <span class="text-sm text-danger">{{ errors.payment_agree }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="wiz_step == 6" class=" verify-view">
                <div class="col-md-12">
                  <div class="card-body card pt-5 pb-5">
                    <h3 class="text-center">Thank You!</h3>
                    <p class="text-center">Your Application for MVR Review Submitted!</p>
                    <div class="col-md-4">

                    </div>
                    <div class="offset-4 col-md-4 text-center mb-4">
                      <div class="">
                        <button type="button" @click.prevent="viewApplication()" class="btn btn-outline-primary cancelbtn btn-previious">Check Application</button>
                      </div>
                    </div>
                    <div class="col-md-4">

                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-12 pr-0 text-right mt-2 ">
                <div class="btn-group pb-3" role="group" aria-label="Basic example">
                  <button
                    type="button"
                    v-if="wiz_step != 1 && wiz_step != registerTotalstep && wiz_step != 6"
                    @click.prevent="wizardPrevstep"
                    class="btn btn-outline-primary cancelbtn btn-previious  "
                  >
                    Previous Step
                  </button>
                  <button
                    type="button"
                    v-if="wiz_step != registerTotalstep && wiz_step != 6"
                    @click.prevent="wizardNextstep"
                    class="btn btn-secondary btnnextstp btndisabled btn-next-success"
                  >
                    {{ button.nextStepButton }}
                  </button>
                </div>
              </div>
            </div>
            <div v-else-if="backgroundScreen != null">
              <section>
                <div class="profile-body card user-details">
                  <div class="card-header register-title">
                    <h3>Application Status</h3>
                    <b-button @click.prevent="applyNew(event);" class="pull-right">Purchase MVR</b-button>
                  </div>
                  <div class="card-body">
                    <div class="row" v-if="props_fields && props_fields.personal && backgroundScreen.ncsi">
                      <div  class="col-md-6 col-lg-4" v-if="props_fields.personal.title">
                        <div class="form-group">
                          <label class="form-label">{{props_fields.personal.title.label || 'Title'}}</label>
                          <span >{{ profileData.info.title || "N/A"}}</span>
                        </div>
                      </div>
                      <div  class="col-md-6 col-lg-4 ">
                        <div class="form-group">
                          <label class="form-label">{{props_fields.personal.firstname.label || 'First Name'}}</label>
                          <span >{{ backgroundScreen.ncsi.first_name || "N/A"}}</span>
                        </div>
                      </div>
                      <div  class="col-md-6 col-lg-4">
                        <div class="form-group">
                          <label class="form-label">{{props_fields.personal.middlename.label || 'Middle Initial'}}</label>
                          <span >{{ backgroundScreen.ncsi.middle_name || ""}}</span>
                        </div>
                      </div>
                      <div  class="col-md-6 col-lg-4">
                        <div class="form-group">
                          <label class="form-label" >{{props_fields.personal.lastname.label || 'Last Name'}}</label>
                          <span >{{ backgroundScreen.ncsi.last_name || "N/A"}}</span>
                        </div>
                      </div>
                      <div  class="col-md-6 col-lg-4">
                        <div class="form-group">
                          <label class="form-label">{{props_fields.personal.birthday.label || 'Birthday'}}</label>
                          <span >{{ backgroundScreen.ncsi.dob || "N/A"}}</span>
                        </div>
                      </div>
                      <div  class="col-md-6 col-lg-4">
                        <div class="form-group">
                          <label class="form-label">{{props_fields.contact.email.label || 'Email'}}</label>
                          <span >{{ backgroundScreen.ncsi.email || "N/A" }}</span>
                        </div>
                      </div>
                      <div  class="col-md-6 col-lg-4">
                        <div class="form-group">
                          <label class="form-label">{{'Mother\'s maiden name'}}</label>
                          <span >{{ backgroundScreen.ncsi.mother_maiden_name || "N/A" }}</span>
                        </div>
                      </div>
                      <div  class="col-md-6 col-lg-4">
                        <div class="form-group">
                          <label class="form-label">{{props_fields.contact.mobilephone.label || 'Country Code with Mobile'}}</label>
                          <span >{{ backgroundScreen.ncsi.phone || "N/A" }}</span>
                        </div>
                      </div>
                      <div  class="col-md-6 col-lg-4">
                        <div class="form-group">
                          <label class="form-label">{{props_fields.contact.address1.label || 'Mailing Address'}}</label>
                          <span >{{ backgroundScreen.ncsi.stree1 || "N/A" }}</span>
                        </div>
                      </div>
                      <div  class="col-md-6 col-lg-4">
                        <div class="form-group">
                          <label class="form-label">{{props_fields.contact.country.label || 'Country'}}</label>
                          <span >{{ backgroundScreen.ncsi.country || "N/A" }}</span>
                        </div>
                      </div>
                      <div  class="col-md-6 col-lg-4">
                        <div class="form-group">
                          <label class="form-label">{{props_fields.contact.city.label || 'City'}}</label>
                          <span >{{ backgroundScreen.ncsi.city || "N/A" }}</span>
                        </div>
                      </div>
                      <div  class="col-md-6 col-lg-4">
                        <div class="form-group">
                          <label class="form-label">{{props_fields.contact.state_code.label || 'State'}}</label>
                          <span >{{ backgroundScreen.ncsi.state || "N/A" }}</span>
                        </div>
                      </div>
                      <div class="col-md-6 col-lg-4">
                        <div class="form-group">
                          <label class="form-label">{{ props_fields.contact.zip.label || 'Postal Code' }}</label>
                          <span>{{ backgroundScreen.ncsi.zip || "N/A" }}</span>
                        </div>
                      </div>
                      <div class="col-md-6 col-lg-4">
                        <div class="form-group">
                          <label class="form-label">{{ 'Lived Status' }}</label>
                          <span>{{ backgroundScreen.ncsi.lived_status_text || "N/A" }}</span>
                        </div>
                      </div>
                      <div class="col-md-6 col-lg-4">
                        <div class="form-group">
                          <label class="form-label">{{ 'Resident of' }}</label>
                          <span>{{ backgroundScreen.ncsi.state_specific_home || "N/A" }}</span>
                        </div>
                      </div>
                      <div class="col-md-6 col-lg-4">
                        <div class="form-group">
                          <label class="form-label">{{ 'Employment to work in' }}</label>
                          <span>{{ backgroundScreen.ncsi.state_specific_work || "N/A" }}</span>
                        </div>
                      </div>
                      <div class="col-md-6 col-lg-4">
                        <div class="form-group">
                          <label class="form-label">Transaction Amount (USD)</label>
                          <span>{{ (profileData.info.background_mvr_review.payment != null)?profileData.info.background_mvr_review.payment.amount:"N/A" || "N/A" }}</span>
                        </div>
                      </div>
                      <div class="col-md-6 col-lg-4">
                        <div class="form-group">
                          <label class="form-label">Transaction Id</label>
                          <span>{{ (profileData.info.background_mvr_review.payment != null)?profileData.info.background_mvr_review.payment.transaction_code:"N/A" || "N/A" }}</span>
                        </div>
                      </div>
                      <div class="col-md-6 col-lg-4">
                        <div class="form-group">
                          <label class="form-label">Transaction Date</label>
                          <span>{{ (profileData.info.background_mvr_review.payment != null)?usdate(profileData.info.background_mvr_review.payment.transaction_time) :"N/A" || "N/A" }}</span>
                        </div>
                      </div>
                      <div class="col-md-4" v-if="profileData.info.background_mvr_review">
                        <div class="form-group">
                          <label class="form-label">MVR Screen Status <span v-if="profileData.info.background_mvr_review != null" v-b-tooltip.hover :title="profileData.info.background_mvr_review.background_screening_info"> <img  v-bind:src="'/dist/img/memberregistration/tooltip-icon-small.png'" alt="tooltipicon" /></span></label>
                          <span class="text-success" v-if="profileData.info.background_mvr_review.status == 2"><img  class="mr-1" src="/dist/img/profile/ico-status-current.png"> {{ profileData.info.background_mvr_review.background_screening_status_text || "N/A"}}</span>
                          <span class="text-warning" v-else><img class="mr-1" src="/dist/img/profile/ico-status-pending.png"> {{profileData.info.background_mvr_review.background_screening_status_text || "N/A"}}</span>
                        </div>
                      </div>
                      <div  class="col-md-4" v-else>
                        <div class="form-group">
                          <label class="form-label">MVR Screen Status</label>
                          <span class="text-warning"><img class="mr-1" src="/dist/img/profile/ico-status-pending.png"> N/A</span>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="form-group">
                          <label class="form-label">Waivers and Notices</label>
                          <button class="btn btn-success ml-1 mt-0" @click="downloadDocs(profileData.info.background_mvr_review.id)">
                            <i class="fas" :class="{ 'fa-circle-notch fa-spin' : waiverDownloading, 'fa-download': !waiverDownloading }"></i>
                          </button>
                        </div>
                      </div>
                      <div class="col-md-4" v-if="(backgroundScreen.status == 4) && (profileData.info.background_mvr_review.payment != null)">
                        <div class="form-group">
                          <label class="form-label">MVR Re-Submit</label>
                          <button class="btn btn-success ml-1 mt-0" @click="mvrModal"><span class="fa fa-edit"></span> Re-submit </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
            <div v-else-if="memberAge < 16">
              <section>
                <div class="card donation">
                  <div class="card-header register-title">
                    <h3>Validation</h3>
                  </div>
                  <div class="card-body">
                    <div class="row">
                      <div class="col-md-12">
                        <p class="text-center">You must be 16+ to do MVR Screening</p>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
            <div v-else-if="groupCommittee.screening == false">
              <section>
                <div class="card donation">
                  <div class="card-header register-title">
                    <h3>Validation</h3>
                  </div>
                  <div class="card-body">
                    <div class="row">
                      <div class="col-md-12">
                        <p class="text-center">No MVR Screening Required</p>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
            <b-modal id="modalMvrReSubmission" size="lg" :ok-disabled=OkDisable ok-title="Update" @ok="updateMvrStatus" ref="modal-mvr-resubmission" centered title="MVR Re-Submission">
            <form>
              <b-overlay :show="showLoader" no-wrap></b-overlay>
              <div class="row">
                <div class="col-md-3">
                    <div class="form-group">
                        <label for="firstName" class="form-label"> First Name<sup class="text-danger">*</sup></label>
                        <input type="text" class="form-control" name="first_name" v-model="mvrForm.first_name">
                        <span class="text-sm text-danger">{{ errors.first_name}}</span>
                    </div>

                </div>
                <div class="col-md-3">
                    <div class="form-group">
                    <label for="middleinitial" class="form-label">Middle Initial</label>
                    <input type="text" class="form-control" name="middle_name" v-model="mvrForm.middle_name">
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="form-group">
                    <label for="lastname" class="form-label">Last Name<sup class="text-danger">*</sup></label>
                    <input type="text" class="form-control" name="last_name" v-model="mvrForm.last_name">
                      <span class="text-sm text-danger">{{ errors.last_name}}</span>
                    </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group birthdate">
                    <label for="lastname" name="dob" class="form-label">DOB<sup class="text-danger">*</sup></label>
                    <date-pick
                        v-model="mvrForm.dob"
                        :value="mvrForm.dob | formatDob"
                        :pickTime="false"
                        name="mvrForm.dob"
                        :format="'MM/DD/YYYY'"
                        v-mask="'##/##/####'"
                        :isDateDisabled="isFutureDate"
                        placeholder="MM/DD/YYYY"
                        :parseDate="parseDatePick"
                    ></date-pick>
                    <span class="text-sm text-danger">{{ errors.dob}}</span>
                  </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                    <label for="lastname" class="form-label">Email<sup class="text-danger">*</sup></label>
                    <input type="email" class="form-control" name="email" v-model="mvrForm.email">
                      <span class="text-sm text-danger">{{ errors.email}}</span>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                      <label for="lastname" class="form-label">Driver's License<sup class="text-danger">*</sup></label>
                      <input type="text" class="form-control license" name="driverlicense" v-model="mvrForm.driverlicense" placeholder="Driver's License">
                      <span class="text-sm text-danger">{{ errors.driverlicense}}</span>                               
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                      <label for="driverlicense_extension" class="form-label">DL State<sup class="text-danger">*</sup></label>
                      <!-- <input type="text" class="form-control ext" v-model="form.driverlicense_extension" placeholder="ST" @keypress="isAlphabets(event)"> -->
                      <select class="form-control" name="driverlicense_extension" v-model="mvrForm.driverlicense_extension">
                          <option value="">Select</option>
                          <option  v-for="listOfState in listOfStates" :key="listOfState.state_code" :value="listOfState.state_code">{{ listOfState.state }}</option>
                      </select>
                      <span class="text-sm text-danger">{{ errors.driverlicense_extension}}</span>                                 
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                        <div class="phone-nu-wrapper">
                        <label for="phone" class="form-label">Phone</label>
                        <vue-tel-input name="phone" v-model="mvrForm.phone" ref="phone" maxlength="20" v-bind="mobileProps"  @validate="validateTelinput"  :validCharactersOnly="true"></vue-tel-input>
                        <span class="text-sm text-danger">{{ errors.phone }}</span>
                        </div>
                    </div>
                </div>
                <div class="col-md-8">
                    <div class="form-group">                                    
                      <p class="font-13">To avoid delays: please provide your Mother's maiden name.</p>
                      <input type="text" class="form-control" name="mother_maiden_name" v-model="mvrForm.mother_maiden_name">
                    </div>
                </div>
                <hr>
                <div class="col-md-12">
                    <h4> Address History</h4>
                    <p>The application will accept only the two-letter state abbreviation in the ST field (ex. CO for Colorado).</p>

                </div>
                <div class="col-md-4">
                    <div class="form-group">
                    <label for="lastname" class="form-label">Street<sup class="text-danger">*</sup></label>
                    <input type="text" class="form-control" name="street1" v-model="mvrForm.street1">
                      <span class="text-sm text-danger">{{ errors.street1}}</span>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                    <label for="lastname" class="form-label">City<sup class="text-danger">*</sup></label>
                    <input type="text" class="form-control" name="city" v-model="mvrForm.city">
                      <span class="text-sm text-danger">{{ errors.city}}</span>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                    <label for="lastname" class="form-label">State/Province<sup class="text-danger">*</sup></label>
                    <select class="form-control" id="state" name="state" v-model="mvrForm.state" v-if="form.country == 'USA'">
                    <option value="">Select</option>
                    <option  v-for="listOfState in listOfStates" :key="listOfState.state_code" :value="listOfState.state_code">{{ listOfState.state }}</option>
                    </select>
                    <select class="form-control" id="state" v-model="form.state" name="form.state" v-else-if="form.country == 'Canada'">
                    <option value="">Select</option>
                    <option  v-for="listOfState in listOfCAStates" :key="listOfState.state_code" :value="listOfState.state">{{ listOfState.state }}</option>
                    </select>
                    <input type="text" v-else class="form-control" v-model="form.state" name="form.state_code">
                      <span class="text-sm text-danger">{{ errors.state}}</span>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                    <label for="lastname" class="form-label">Zip Code<sup class="text-danger">*</sup></label>
                    <input type="text" class="form-control" name="zip" v-model="mvrForm.zip">
                      <span class="text-sm text-danger">{{ errors.zip}}</span>
                    </div>
                </div>      
                <div class="col-md-4">
                  <div class="form-group">
                      <label for="lastname" class="form-label">Country<sup class="text-danger">*</sup></label>
                      <select class="form-control" id="country" @change="countryChange" name="country" v-model="mvrForm.country">
                      <option value="">Select</option>
                      <option :value="listOfCountry.country" v-for="listOfCountry in listOfCountries" :key="listOfCountry.country" :selected="listOfCountry.country == 'Bahamas'">{{ listOfCountry.country }}</option>
                      </select>
                      <span class="text-sm text-danger">{{ errors.country}}</span>
                  </div>
                </div>     
              </div>
            </form>
            </b-modal>
          </div>
          <div class="col-md-12" v-else>
            <div class="card">
              <div class="card-body">
                <h5 class="text-center text-danger">MVR Review purchase is disabled now. Come again!</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from 'moment';
import axios from "axios";
import Vue from "vue";
import * as $ from "jquery";
import CustomAlert from "../../CustomAlert";

Vue.filter('formatDob', function(value) {
  if (value) {
    return moment(String(value)).format('MM/DD/YYYY')
  }
});
const validateName = (name, fieldName = '') => {
  let fieldText = ( fieldName != '' ? fieldName : "This field");
  if (name ==  undefined || !name.length) {
    return { valid: false, error: fieldText+ " is required" };

  }
  /*if (!name.match(/^[a-zA-Z' ]+$/))
  {

    return { valid: false, error:  fieldText+ " accept only Alphabets" };
  }*/
  console.log(name);
  return { valid: true, error: null };
};
const validateZip = (zipCode) => {
  if(!zipCode.match(/^\d{5}(-\d{4})?$/) || !zipCode.length ){
    return { valid: false, error: "Postal Code Must Be Atleast 5 Character" };
  }
  return { valid: true, error: null };
};
const validateCanadaZip = (zipCode) => {
  if(zipCode.length < 3 || !zipCode.length ){
    return { valid: false, error: "Postal Code Invalid" };
  }
  return { valid: true, error: null };
};
const validateFirstName = (name, fieldName = '') =>  {
  let names = '';
  if(name != null){
    names = name.trim();
  }
  let fieldText = ( fieldName != '' ? fieldName : "This field");

  if (!names.length) {
    return { valid: false, error: fieldText+ " is required" };
  }
  if (!names.match(/^[a-zA-Z\s]*$/))
  {
    return { valid: false, error:  fieldText+ " must be letters only. No other characters" };
  }
  return { valid: true, error: null };
};
const validateLastName = (name, fieldName = '') =>  {
  let names = '';
  if(name != null){
    names = name.trim();
  }
  let fieldText = ( fieldName != '' ? fieldName : "This field");

  if (!names.length) {
    return { valid: false, error: fieldText+ " is required" };
  }
  if (!names.match(/^[a-zA-Z-\s]*$/))
  {
    return { valid: false, error:  fieldText+ " must be letters and hyphens only. No other characters" };
  }
  return { valid: true, error: null };
};

const validatePhone = (phone, validTelinput, phoneField= '' ) => {
  let phoneText = ( phoneField != '' ? phoneField : "This field");
  if (!phone.length) {
    return { valid: false, error: phoneText+' is required .' };
  }
  if(validTelinput == false && phone.length){
    return { valid: false, error: 'Enter the Exact phone number(Example:+XX XX XXXX XXXX).' };
  }
  return { valid: true, error: null };
}

const validateDob = (dob, fieldDob = '') => {
  let fieldDobText = (fieldDob != '' ? fieldDob : "This field");
  if (!dob.length) {
    return { valid: false, error: fieldDobText+" is required" };
  }
  /*if (!dob.match(/^\w+([.-]?\w+)_@\w+(_[_.-]?\w+)_(.\w{2,3})+$/)) {
  return { valid: false, error: "Please, enter a valid data of birth." };
  }*/
  return { valid: true, error: null };
};
const validateGender = (gender,fieldGender = '') => {
  let genderText = ( fieldGender != '' ? fieldGender : "This field");
  if (!gender) {
    return { valid: false, error: genderText+" is required" };
  }
  return { valid: true, error: null };
};
const validateSelect = select => {
  console.log('m select');
  console.log(select);
  if (select == "" || select == null) {
    return { valid: false, error: "This field is required" };
  }
  return { valid: true, error: null };
};
const validateEmail = (email, fieldEmail='') => {
  let emailText= (fieldEmail != '' ? fieldEmail : "This field");
  if (!email.length) {
    return { valid: false, error: emailText+" is required" };
  }
  if (!email.match(/^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/)) {
    return { valid: false, error: "Please enter a valid email." };
  }
  return { valid: true, error: null };
};
const validateWaiverInitials = name => {
  if (!name) {
    return { valid: false, error: "Please enter your Initials to proceed" };
  } else {
    if(name.length != 2) {
      return { valid: false, error: "You must enter two letters to initial" };
    }
  }
  return { valid: true, error: null };
};
const validateWaiverCheckbox = checkbox => {
  console.log("resss="+checkbox);
  if (!checkbox) {
    return { valid: false, error: "Please acknowledge by checking the box" };
  }
  return { valid: true, error: null };
};

const validateStateName = name => {
  console.log('v state');
  console.log(name);
  if (name == null || name == "") {
    return { valid: false, error: "This field is required" };
  }
  return { valid: true, error: null };
};
const validateCardNumber = cardNumber => {
  if (!cardNumber.length || cardNumber == "") {
    return { valid: false, error: "This field is required" };
  }
  if ((cardNumber.replace(/\s/g,'')).length < 14) {
    return { valid: false, error: "Card number must be greater than 13 digits" };
  }
  return { valid: true, error: null };
};
const validateCvv = cvv => {
  if (!cvv.length || cvv == "") {
    return { valid: false, error: "This field is required" };
  }
  if (!cvv.match(/^[0-9]{3,4}$/)) {
    return { valid: false, error: "CVV must be 3 or 4 digits"};
  }
  return { valid: true, error: null };
};
const validateSsnNumber = ssnNumber => {
  if (!ssnNumber.length || ssnNumber.trim() == "") {
    return { valid: false, error: "This field is required" };
  }
  if ((ssnNumber.replace(/\s/g,'')).length != 9) {
    return { valid: false, error: "Please enter valid SSN" };
  }
  return { valid: true, error: null };
};
const validateSsnWaiverNumber = (ssnNumber) => {
  if (!ssnNumber.length || ssnNumber.trim() == "") {
    return { valid: false, error: "This field is required" };
  }
  if ((ssnNumber.replace(/\s/g,'')).length != 4) {
    return { valid: false, error: "Please enter last 4 digits of your SSN" };
  }
  return { valid: true, error: null };
};
const validateDrivingLicense = drivingLicense => {
  if (!drivingLicense.length || drivingLicense.trim() == "") {
    return { valid: false, error: "This field is required" };
  }
  if ((drivingLicense.replace(/\s/g,'')).length < 1) {
    return { valid: false, error: "Please enter valid Driving License" };
  }
  return { valid: true, error: null };
};
const validateDrivingExtension = licenseExtension => {
  if (!licenseExtension.length || licenseExtension.trim() == "") {
    return { valid: false, error: "This field is required" };
  }
  if ((licenseExtension.replace(/\s/g,'')).length != 2) {
    return { valid: false, error: "Please enter valid State Code" };
  }
  return { valid: true, error: null };
};
export default {
  name: "backroundscreeningform",
  components: { CustomAlert },
  data() {
    return {
      OkDisable :false,
      waiverDownloading: false,
      IpAddressVal: '',
      loading: true,
      waiver_index: 0,
      total_waiver: 3,
      wiz_step: 1,
      waiverSign: [],
      couponApplied: {
        status: 0,
        coupon_code: '',
        amount: 0.00,
        text: ''
      },
      totals: {
        sub_total: 0.00,
        grand_total: 0.00,
      },
      couponErrors: {},
      waiverTypeData: [],
      registerTotalstep: 8,
      form: {
        lived_status: '',
        first_name: '',
        middle_name: '',
        last_name: '',
        phone: '',
        email: '',
        dob: '',
        ssn: '',
        mother_maiden_name: '',
        address_type: 1,
        street1: '',
        street2: '',
        city: '',
        state: '',
        zip: '',
        country: 'USA',
        country_code: 'US',
        state_specific_work: '',
        state_specific_home: '',
        signature_name: '',
        type: '',
        fee: 15.00,
        processingFee: 0.00,
        grand_total: 0.00,
        processingFeePercent: 3,
        seeking_prospective_first: '',
        seeking_prospective_second: '',
        resident_first: '',
        resident_second: '',
        driverlicense: '',
        driverlicense_extension: '',
        ca_receive_icr: '',
        background_screening_type_id: 5
      },
      mvrForm: {
        first_name: '',
        middle_name: '',
        last_name: '',
        phone: '',
        email: '',
        dob: '',
        mother_maiden_name: '',
        street1: '',
        city: '',
        state: '',
        zip: '',
        country: '',
        driverlicense: '',
        driverlicense_extension: '',
      },
      alertMessage: null,
      displayAlert: false,
      showLoader:false,
      type: 'MVR Review',
      country: 'USA',
      fees: {
        fee: 0.00,
        processingFee: 0.00,
        grand_total: 0.00,
        processingFeePercent: 3,
      },
      valid: false,
      errors: {},
      currentDate: moment().format('MM/DD/YYYY hh:mm'),
      paymentForm: {
        cardNumber: '',
        expiration_year: '',
        expiration_month: '',
        cvv: '',
        amount: '',
        owner: '',
        name_on_card: "",
        registration_id: '',
        billing_firstname: '',
        billing_lastname: '',
        billing_company: 'USA Waterski',
        billing_addressline1: '',
        billing_zip: '',
        billing_state: '',
        billing_state_code: '',
        billing_city: '',
        billing_country: '',
        billing_phone1: '',
        auto_renew: 1,
        background_screening_type_id: 5,
        visible: true,
        coupon_id:'',
        discount:'',
        waiverSign: [],
      },
      prop_footer: {},
      prop_confirmation: {
        agree: "I agree to the USA-WSWS <a href='/privacy-policy' target='_blank'>Terms & Conditions policy</a>",
        label: "By submitting this information, you agree to the <a href='/privacy-policy' target='_blank'>Privacy Policy</a> and <a href='/terms-and-conditions' target='_blank'>Terms & Conditions</a> policy,<span class='auto_renew_text_sign'>including AUTO-RENEWAL and</span> understand that there are NO REFUNDS.",
        note: "Please check the box and add your initials in the space provided.",
      },
      button: {
        nextButton: 'Next',
        nextStepButton: 'Next Step',
        coupon: 'Apply Coupon'
      },
      bgsId: '',
      waiverData: [],
      signedWaiverData: [],
      memberAge: '',
      backgroundScreen: null,
      props_fields: null,
      profileData: {
        info:[],
        SafeSport: {
          ActiveStatus: ''
        },
        BGScreening: {
          BackgroundReportPackage: {
            ScreeningStatus: {
              OrderStatus: ''
            }
          }
        },
      },
      validTelinput:false,
      mobileProps: {
        mode: "international",
        defaultCountry: "US",
        inputOptions:{
          autocomplete:"on",
          autofocus:false,
          id:"",
          maxlength:20,
          name:"telephone",
          placeholder:"Mobile Number",
          readonly:false,
          required:false,
          showDialCode:false,
          styleClasses:"",
          tabindex:0,
          type:"tel"
        }
      },
    };
  },
  methods: {
    wizardNextstep: function () {
      console.log(this.wiz_step);
      if(this.wiz_step == 2) {
        if(!this.validate()) {
          return;
        } else {
          this.wiz_step = 3;
          return;
        }
      } else if (this.wiz_step == 3) {
        if (!this.validateStates()) {
          return;
        }
        if(this.form.state_specific_work == 'CA') {
          this.total_waiver = 8;
        } else if(this.form.state_specific_work == 'NY') {
          this.total_waiver = 4;
        }
      } else if (this.wiz_step == 4) {
        if (!this.validateWaiverForm()) {
          return;
        }
          if(this.waiver_index == this.total_waiver) {
            this.applyBackGroundScreen();
            // this.wiz_step = 5;
            return;
          } else {
            if(this.waiver_index == 2) {
              this.waiver_index = 9;
              this.total_waiver = 4;
              if(this.form.state_specific_home == 'CA' || this.form.state_specific_work == 'CA'){
                this.total_waiver = 8;
              }
            } else if(this.waiver_index == 1 && this.total_waiver == 3 && this.subdomain == 'shooting') {
              this.waiver_index = 7;
              this.total_waiver = 7;
            }else if(this.waiver_index == 1 && this.total_waiver == 7 && this.subdomain == 'shooting') {
              this.waiver_index = 7;
              this.total_waiver = 7;
            }else if(this.waiver_index == 3) {
              this.waiver_index = 7;
              this.total_waiver = 7;
              if(this.form.state_specific_home == 'CA' || this.form.state_specific_work == 'CA'){
                this.total_waiver = 8;
              }
            }else if(this.waiver_index == 7 && this.total_waiver == 4) {
              this.waiver_index = 7;
              if(this.form.state_specific_home == 'CA' || this.form.state_specific_work == 'CA'){
                this.total_waiver = 8;
              } else {
                this.total_waiver = 7;
              }
            } else if(this.waiver_index == 2 && this.total_waiver == 7 && this.form.state_specific_work != 'NY' && this.form.state_specific_work != 'CA') {
              this.waiver_index = 7;
              this.total_waiver = 7;
            }else if(this.waiver_index == 2 && this.total_waiver == 7) {
              this.waiver_index = 4;
            } else if(this.waiver_index == 6) {
              this.waiver_index = 3;
            } else if(this.waiver_index == 9) {
              if(this.total_waiver == 8){
                this.waiver_index = 4;
              } else {
                this.waiver_index = 3;
              }
            } else {
              this.waiver_index++;
            }
            return;
          }

      } else if (this.wiz_step == 5) {
        if (this.paymentForm.amount == 0.0) {
          this.paymentApi();
          return;
        } else {
          if (this.validatePayment()) {
            this.paymentApi();
            return;
          } else {
            return;
          }
        }
      }
      this.wiz_step++;
      console.log('wiz_step='+this.wiz_step);
    },
    wizardPrevstep: function () {
      if(this.waiver_index == 8 || this.waiver_index == 0) {
        if(this.wiz_step == 3 & this.subdomain == 'shooting') {
          this.wiz_step = 1;
        }else if(this.wiz_step == 5 & this.subdomain == 'waterski') {
          this.wiz_step = 4;
        }else if(this.wiz_step == 5 & this.subdomain == 'shooting') {
          this.wiz_step = 4;
        }else if(this.wiz_step == 4 & this.subdomain == 'shooting' & this.form.backgroundScreeningType==2) {
          this.wiz_step = 10;
        } else if(this.wiz_step == 9 & this.subdomain == 'shooting') {
          this.wiz_step = 3 ;
        } else if(this.wiz_step == 10 & this.subdomain == 'waterski') {
          this.wiz_step = 3;
        } else {
          this.wiz_step--;
        }
        if(this.form.state_specific_work != 'CA' && this.form.state_specific_work != 'NY') {
          this.total_waiver = 7;
        } else if(this.form.state_specific_work == 'NY') {
          this.total_waiver = 7;
        }
      }
      if(this.waiver_index == 7) {
        if (this.subdomain=='shooting'){
          this.waiver_index = 1;
          return;
        }
        if(this.form.state_specific_work != 'CA' && this.form.state_specific_work != 'NY') {
          this.waiver_index = 3;
          this.total_waiver = 4;
          return;
        } else if(this.form.state_specific_work == 'NY') {
          this.waiver_index = 3;
          this.total_waiver = 4;
          return;
        } else {
          this.waiver_index = 7;
        }
      }
      if(this.waiver_index == 8 && this.subdomain=='shooting') {
        this.waiver_index = 0;
        return;
      }
      if(this.waiver_index == 4 && (this.form.state_specific_work == 'CA' || this.form.state_specific_home == 'CA')) {
        this.waiver_index = 9;
        return;
      }
      if(this.waiver_index == 9) {
        this.waiver_index = 2;
        return;
      }
      if(this.waiver_index == 3) {
        this.waiver_index = 9;
        return;
      }
      if(this.waiver_index != 0) {
        this.waiver_index--;
      }
    },
    countryChange(){
      this.form.state="";
      let countryObj = this.listOfCountries.find(item => item.country === this.form.country);
      this.form.country_code = countryObj?.code;
    },
    livedStatus(status) {
      console.log("Status"+status);
      this.form.lived_status = status;
    },
    loadMembersInfo() {
      axios
          .get(this.basePath + "api/member/my_profile", {
            headers: this.memberHeaders,
          })
          .then((response) => {
            let profileData = response.data.data;
            this.profileData.info = profileData;
            var props = Object.keys(this.form);
            if(props.length) {
              props.forEach(code => {
                //this.form[code] = profileData[code];
                /*if(code == 'address_type' && profileData[code] == null) {
                  this.form[code] = 1;
                }*/
              });
              /*this.form.street1 = this.address1;
              this.form.street2 = profileData.address2;
              this.form.phone = profileData.phone_1;
              this.form.state = profileData.state_code;
              this.form.country = this.country;*/
              this.background_screening_type_id = 5;
              this.memberAge = this.getAge(profileData.dob);
              this.backgroundScreen = profileData.background_mvr_review;
              if(profileData.groupCommittee != null)
                this.groupCommittee = profileData.groupCommittee;

            }
            this.loading = false;
          })
          .catch((error) => {
            console.log(error);
          });
    },
    applyBackGroundScreen() {
      this.button.nextStepButton = 'Submitting...';
      this.waiverSign.forEach((value, index) => {
        this.waiverSign[index].type = this.waiverTypeData[index].type;
        this.waiverSign[index].ncsi_document_id = this.waiverTypeData[index].ncsi_document_id;
        this.waiverSign[index].slug = this.waiverTypeData[index].slug;
      });
      this.form.waiverSign = this.waiverSign;
      this.form.init_amount = this.fees.fee;
      axios.post(this.basePath + 'api/member/apply_back_ground_screen', this.form, {headers: this.memberHeaders})
          .then((res) => {
            if (res.data.status == 'success') {
              this.bgsId = res.data.data.id;
              console.log("s0="+this.wiz_step);
              if(!this.groupCommittee.payment) {
                this.wiz_step +=2;
                console.log("s2="+this.wiz_step);
              } else {
                this.wiz_step++;
                console.log("s1="+this.wiz_step);
              }
              this.waiver_index++;
              console.log("s="+this.wiz_step);
              this.button.nextStepButton = 'Next Step';
              this.showLoader=false;
              return res;
            } else {
              this.button.nextStepButton = 'Next Step';
              this.showLoader=false;
              return res;
            }
          })
          .catch((error) => {
            console.log('Error:' + error);
            this.button.nextStepButton = 'Next Step';
            this.showLoader=false;
            return false;
            // error.response.status Check status code
          }).finally(() => {
        console.log('Finally');
      });
    },
    mvrModal() {
      this.$refs['modal-mvr-resubmission'].show();
      this.mvrForm = this.profileData.info.background_mvr_review.ncsi;
      this.mvrForm.driverlicense = this.profileData.info.driver_license;
      if (this.profileData.info.background_mvr_review.ncsi.dob) {
        this.mvrForm.dob = moment(this.profileData.info.background_mvr_review.ncsi.dob).format('MM/DD/YYYY');
      }
    },
    updateMvrStatus(bvModalEvt){
      bvModalEvt.preventDefault()
      this.mvrvalidate()
      if(this.mvrvalidate()) {
        this.OkDisable = true;
        let memId = this.profileData.info.background_mvr_review.id;
        this.showLoader =true;
      axios.post(this.basePath + 'api/update/mvrstatus/'+memId,this.mvrForm,{ headers:this.memberHeaders })
          .then(function (response) {
            this.mvrForm =response.data.data;
            this.alertMessage = response.data.message;
            this.displayAlert = true;
            this.showLoader = false;
            this.OkDisable = false;
            this.$refs['modal-mvr-resubmission'].hide();
            window.location.reload();
          }.bind(this))
          .catch(function (error) {
            console.log(error);
          });
      }
      
    },
    setDangerBg(){
      setTimeout(() => {
        $('#alert_box').children('div').removeClass('alert-success');
        $('#alert_box').children('div').addClass('alert-danger');
      }, 100);
      return false;
    },
    removeDangerBg(){
      setTimeout(() => {
        $('#alert_box').children('div').removeClass('alert-danger');
        $('#alert_box').children('div').addClass('alert-success');
      }, 100);
    },
    resetAlert(){
      this.displayAlert = false;
      this.removeDangerBg();
    },
    waiverApi() {
      console.log("Waiver!");
      axios.get(this.basePath + 'api/waivers/' + this.bgsId)
          .then((res) => {
              this.waiverData = res.data.data;
            return res;
          })
          .catch((error) => {
            console.log('Error:' + error);
            //this.step--;
            //this.button.nextButton = 'Next';
            return false;
            // error.response.status Check status code
          }).finally(() => {
      });
    },
    getAge(birth_date) {
      let birth_date_arr = birth_date.split('/');
      console.log(birth_date_arr);
      let today_date = new Date();
      let today_year = today_date.getFullYear();
      let today_month = today_date.getMonth();
      let today_day = today_date.getDate();
      let age = today_year - birth_date_arr[2];

      if (today_month < (birth_date_arr[0] - 1)) {
        age--;
      }
      if (((birth_date_arr[0] - 1) == today_month) && (today_day < birth_date_arr[1])) {
        age--;
      }
      return age;
    },
    isFutureDate(date) {
      const currentDate = new Date();
      return date > currentDate;
    },
    validateType() {
      this.errors = {}
      this.valid = false;
      if(this.form.lived_status == '') {
        this.errors.status = "Please choose living status";
      } else {
        this.errors.status = "";
        this.valid = true;
      }
      console.log("f="+this.valid);
      return this.valid;
    },
    validate() {
      this.errors = {}
      this.valid = false;
      let validSsnValid = false;
      const validFirstName = validateFirstName(this.form.first_name, "First name");
      this.errors.first_name = validFirstName.error;

      const validLastName = validateLastName(this.form.last_name, "Last name");
      this.errors.last_name = validLastName.error;

      const validDob = validateDob(this.form.dob, "Birth Date");
      this.errors.dob = validDob.error;

      const validDriverLicense = validateDrivingLicense(this.form.driverlicense, "Driver's License");
      this.errors.driverlicense = validDriverLicense.error;

      const validDriverLicenseExt = validateDrivingExtension(this.form.driverlicense_extension, "Driver's Extension");
      this.errors.driverlicense_extension = validDriverLicenseExt.error;

      const validEmail = validateEmail(this.form.email, "Email");
      this.errors.email = validEmail.error;

      const validPhone = validatePhone(this.form.phone, this.validTelinput);
      this.errors.phone = validPhone.error;

      const validAddress = validateName(this.form.street1, "Street");
      this.errors.street1 = validAddress.error;

      const validState = validateSelect(this.form.state, "State");
      this.errors.state = validState.error;

      const validCity = validateName(this.form.city, "City");
      this.errors.city = validCity.error;

      const validZip = this.form.country == 'USA'? validateZip(this.form.zip, "Zip Code") : validateCanadaZip(this.form.zip, "Zip Code");
      this.errors.zip = validZip.error;

      if(this.form.driverlicense_extension == 'pa' || this.form.driverlicense_extension == 'PA') {
        const validSsn = validateSsnNumber(this.form.ssn, "SSN");
        this.errors.ssn = validSsn.error;
        validSsnValid = validSsn.valid;
      } else {
        validSsnValid = true;
      }

      if (validFirstName.valid && validLastName.valid && validDriverLicense.valid && validDriverLicenseExt.valid && validSsnValid && validEmail.valid && validDob.valid && validPhone.valid && validAddress.valid && validCity.valid && validState.valid && validZip.valid) {
        let dob = this.getAge(this.form.dob);

        if (!this.validateDate(this.form.dob)) {
          this.valid = false;
          this.errors.dob = 'Invalid Date';
        } else {
          this.errors.dob = '';
          this.valid = true;
          if( (this.profileData.info.membership.member_type_id != 3 && dob < 16)){
            this.valid = false;
            this.errors.dob = "You must be 16+ to do MVR Screening";
            window.scrollTo(0, 0);
          }
        }
      }
      return this.valid;
    },
    mvrvalidate() {
      this.errors = {}
      this.valid = false;
      const validFirstName = validateFirstName(this.mvrForm.first_name, "First name");
      this.errors.first_name = validFirstName.error;

      const validLastName = validateLastName(this.mvrForm.last_name, "Last name");
      this.errors.last_name = validLastName.error;

      const validDob = validateDob(this.mvrForm.dob, "Birth Date");
      this.errors.dob = validDob.error;

      const validDriverLicense = validateDrivingLicense(this.mvrForm.driverlicense, "Driver's License");
      this.errors.driverlicense = validDriverLicense.error;

      const validDriverLicenseExt = validateDrivingExtension(this.mvrForm.driverlicense_extension, "Driver's Extension");
      this.errors.driverlicense_extension = validDriverLicenseExt.error;

      const validEmail = validateEmail(this.mvrForm.email, "Email");
      this.errors.email = validEmail.error;

      const validPhone = validatePhone(this.mvrForm.phone, this.validTelinput);
      this.errors.phone = validPhone.error;

      const validAddress = validateName(this.mvrForm.street1, "Street");
      this.errors.street1 = validAddress.error;

      const validState = validateSelect(this.mvrForm.state, "State");
      this.errors.state = validState.error;

      const validCity = validateName(this.mvrForm.city, "City");
      this.errors.city = validCity.error;

      const validZip = this.mvrForm.country == 'USA' ? validateZip(this.mvrForm.zip, "Zip Code") : validateCanadaZip(this.mvrForm.zip, "Zip Code");
      this.errors.zip = validZip.error;

      if (validFirstName.valid && validLastName.valid && validDriverLicense.valid && validDriverLicenseExt.valid && validEmail.valid && validDob.valid && validPhone.valid && validAddress.valid && validCity.valid && validState.valid && validZip.valid) {
        let dob = this.getAge(this.mvrForm.dob);

        if (!this.validateDate(this.mvrForm.dob)) {
          this.valid = false;
          this.errors.dob = 'Invalid Date';
        } else {
          this.errors.dob = '';
          this.valid = true;
          if( (this.profileData.info.membership.member_type_id != 3 && dob < 16)){
            this.valid = false;
            this.errors.dob = "You must be 16+ to do MVR Screening";
            window.scrollTo(0, 0);
          }
        }
      }
      return this.valid;
    },
    validateStates() {
      this.errors = {};
      this.valid = false;
      const validHome = validateSelect(this.form.state_specific_home, "State Specific Home");
      this.errors.state_specific_home = validHome.error;

      const validWork = validateSelect(this.form.state_specific_work, "State Specific Work");
      this.errors.state_specific_work = validWork.error;

      if(validHome.valid && validWork.valid) {
        this.valid = true;
        console.log('1');
        if(this.form.state_specific_work == 'CA') {
          console.log(this.form.state_specific_work);
          const validSeekingFirst = validateSelect(this.form.seeking_prospective_first, "Seeking Prospective Work");
          const validSeekingSecond = validateSelect(this.form.seeking_prospective_second, "Seeking Prospective Work");
          console.log(validSeekingFirst);
          console.log('33333333333333333');
          console.log(validSeekingSecond);
          if(!validSeekingFirst.valid || !validSeekingSecond.valid) {
            console.log('2');
            this.errors.seeking_prospective_first = validSeekingFirst.error;
            this.errors.seeking_prospective_second = validSeekingSecond.error;
            this.valid = false;
          }
        }
        if(this.form.state_specific_home == 'CA') {
          const validResidentFirst = validateSelect(this.form.resident_first, "Resident First");
          const validResidentSecond = validateSelect(this.form.resident_second, "Resident Second");
          if(!validResidentFirst.valid || !validResidentSecond.valid) {
            this.errors.resident_first = validResidentFirst.error;
            this.errors.resident_second = validResidentSecond.error;
            this.valid = false;
          }
        }
      }
      return this.valid;
    },
    validateWaiverForm() {
      this.errors = {};
      this.valid = false;

      const validName = validateName(this.waiverSign[this.waiver_index].name);
      this.errors.name = validName.error;

      const validAgreeFirst = validateWaiverCheckbox(this.waiverSign[this.waiver_index].agree_first);
      this.errors.agree_first = validAgreeFirst.error;

      const validAgreeSecond = validateWaiverCheckbox(this.waiverSign[this.waiver_index].agree_second);
      this.errors.agree_second = validAgreeSecond.error;

      const validSsnNumber = validateSsnWaiverNumber(this.waiverSign[this.waiver_index].ssn);
      this.errors.ssn = validSsnNumber.error;

      if(this.waiver_index == 0 || this.waiver_index == 1 || this.waiver_index == 2 || this.waiver_index == 9) {
        if(validName.valid) {
          this.valid = true;
        }
      } else if(this.waiver_index == 3) {
        if(validAgreeFirst.valid) {
          this.valid = true;
        }
      } else if(this.waiver_index == 4 || this.waiver_index == 5) {
        if(validAgreeFirst.valid) {
          this.valid = true;
        }
      } else if(this.waiver_index == 6) {
        if(validAgreeSecond.valid) {
          this.valid = true;
        }
      } else if(this.waiver_index == 7) {
        if(validName.valid && validAgreeFirst.valid && validSsnNumber.valid) {
          this.valid = true;
        }
      } else if(this.waiver_index == 8) {
        if(validName.valid) {
          this.valid = true;
        }
      }
      return this.valid;
    },
    validatePayment() {
      this.errors = {}
      this.valid = false;

      const validPaymentFirstName = validateName(this.paymentForm.billing_firstname);
      this.errors.billing_firstname = validPaymentFirstName.error;

      const validCardNumber = validateCardNumber(this.paymentForm.cardNumber);
      this.errors.cardNumber = validCardNumber.error;

      const validExpirationYear = validateSelect(this.paymentForm.expiration_year);
      this.errors.expiration_year = validExpirationYear.error;

      const validExpirationMonth = validateSelect(this.paymentForm.expiration_month);
      this.errors.expiration_month = validExpirationMonth.error;

      const validCvv = validateCvv(this.paymentForm.cvv);
      this.errors.cvv = validCvv.error;

      const validAddress1 = validateName(this.paymentForm.billing_addressline1);
      this.errors.billing_addressline1 = validAddress1.error;

      const validCountry = validateSelect(this.paymentForm.billing_country);
      this.errors.billing_country = validCountry.error;

      const validStateCodeSelect = validateSelect(this.paymentForm.billing_state_code);
      const validStateCodeInput = validateStateName(this.paymentForm.billing_state);

      if(validCountry.valid && this.paymentForm.billing_country == 'USA') {
        this.errors.billing_state_code = validStateCodeSelect.error;
      } else {
        this.errors.billing_state = validStateCodeInput.error;
      }

      const validCity = validateName(this.paymentForm.billing_city);
      this.errors.billing_city = validCity.error;

      const validZip =  this.paymentForm.billing_country == 'USA' ? validateZip(this.paymentForm.billing_zip) : validateCanadaZip(this.paymentForm.billing_zip);
      this.errors.billing_zip = validZip.error;

      if (validPaymentFirstName.valid && validCardNumber.valid
          && validExpirationYear.valid && validExpirationMonth.valid && validCvv.valid && validAddress1.valid && validCountry.valid
          && validCity.valid && validZip.valid) {
        this.valid = true;
        if(this.paymentForm.billing_country == 'USA') {
          this.paymentForm.billing_state = '';
          if(!validStateCodeSelect.valid) {
            this.valid = false;
          }
        } else if(!validStateCodeInput.valid) {
          this.paymentForm.billing_state_code = '';
          this.valid = false;
        }
      }

      return this.valid;
    },
    feeCalculate() {
      console.log('fee calculate');
      this.fees.processingFee = parseFloat(parseFloat(this.removeCommaFromNumber(this.fees.fee))/100 * this.removeCommaFromNumber(this.fees.processingFeePercent)).toFixed(2);
      this.fees.grand_total = parseFloat(this.removeCommaFromNumber(this.fees.fee)+this.removeCommaFromNumber(this.fees.processingFee)).toFixed(2);
      this.paymentForm.amount = this.fees.grand_total;
      console.log(this.fees);
      console.log(this.form);
      this.calculateTotals();
    },
    paymentApi() {
      this.showLoader=true;
      console.log("Payment submitted!");
      this.button.nextStepButton = 'Payment is in progress...';
      this.paymentForm.cardNumber = this.paymentForm.cardNumber.replace(/\s/g,'');
      this.paymentForm.name_on_card = this.paymentForm.billing_firstname;
      this.feeCalculate();
      this.paymentForm.init_amount = this.fees.fee;
      let url = (this.paymentForm.visible)?'api/background_screening_paynow/':'api/background_screening_zero_payment/';
      axios.post(this.basePath+url+this.bgsId, this.paymentForm)
          .then((res) => {
            if(res.data.status == 'success') {
              //Perform Success Action
              //alert('success')
              this.showLoader=false;
              console.log('payment success');
              this.button.nextStepButton = 'Next Step';
              console.log(res.data.message);
              this.paymentData = res.data.message;
              //console.log(this.verifyData);
              this.wiz_step++;
              console.log('Finally');
              console.log("f="+this.wiz_step);
              return res;
            } else {
              this.showLoader=false;
              this.errors.payment_agree = res.data.errors;
              this.button.nextStepButton = 'Next Step';
            }
          })
          .catch((error) => {
            this.showLoader=false;
            console.log('Error:'+ error);
            this.errors.payment_agree = error.errors;
            //this.step--;
            this.button.nextStepButton = 'Next Step';
            return false;
            // error.response.status Check status code
          }).finally(() => {

      });
    },
    viewApplication() {
      this.loadMembersInfo();
    },
    applyCoupon() {
      let regData = {};
      regData.price = this.fees.fee;
      regData.coupon_code = this.couponApplied.coupon_code;
      regData.coupon_type_id = 4;
      this.button.coupon = 'Applying Coupon...';
      this.couponErrors = {};
      console.log('---------------');
      console.log(regData);
      axios.post(this.basePath+'api/check_coupon', regData)
          .then((res) => {
            console.log(res.data);
            if(res.data.status == 'success') {
              this.couponApplied.status = 1;
              this.couponApplied.amount = parseFloat(res.data.data.value).toFixed(2);
              this.couponApplied.text = res.data.data.text+' off membership ('+this.couponApplied.coupon_code+')';
              this.couponErrors.success = res.data.message;
              this.paymentForm.coupon_id= res.data.data.id;
              this.paymentForm.discount= res.data.data.value;
              this.calculateTotals();
            } else {
              this.couponErrors.error = res.data.message;
              this.couponApplied.status = 0;
              this.couponApplied.amount = 0.00;
              this.couponApplied.text = '';
              this.calculateTotals();
            }
            this.button.coupon = 'Apply Coupon';
            return res;
          })
          .catch((error) => {
            console.log('Error:'+ error);
            this.button.coupon = 'Apply Coupon';
            return false;
          }).finally(() => {
      });
    },
    removeCoupon(){
      this.couponApplied = {
        status: 0,
        coupon_code: '',
        amount: 0.00,
        text: ''
      };
      this.couponErrors = {};
      this.calculateTotals();
    },
    calculateTotals() {
      let mvrFee = this.fees.fee;
      if(this.couponApplied.status == 1) {
        mvrFee = this.fees.fee - this.couponApplied.amount;
      }
      this.totals.sub_total = parseFloat(this.removeCommaFromNumber(mvrFee));
      this.fees.processingFee = 0;
      this.fees.processingFee = parseFloat(parseFloat(this.removeCommaFromNumber(this.totals.sub_total))/100 * this.removeCommaFromNumber(this.fees.processingFeePercent)).toFixed(2);
      this.fees.grand_total = parseFloat(this.removeCommaFromNumber(this.totals.sub_total)+this.removeCommaFromNumber(this.fees.processingFee)).toFixed(2);
      this.totals.sub_total = this.totals.sub_total < 0 ? 0.00 : parseFloat(this.totals.sub_total).toFixed(2);
      this.fees.grand_total = this.fees.grand_total < 0 ? 0.00 : this.fees.grand_total;
      this.paymentForm.amount = this.fees.grand_total;
      if(this.fees.grand_total == 0.0){
        this.paymentForm.visible = false;
        this.paymentForm.auto_renew = 0;
      } else {
        this.paymentForm.visible = true;
        this.paymentForm.auto_renew = 1;
      }
    },
    applyNew() {
      this.backgroundScreen = null;
      this.wiz_step = 1;
      this.form = {
        lived_status: '',
        first_name: '',
        middle_name: '',
        last_name: '',
        phone: '',
        email: '',
        dob: '',
        ssn: '',
        mother_maiden_name: '',
        address_type: 1,
        street1: '',
        street2: '',
        city: '',
        state: '',
        zip: '',
        country: 'USA',
        state_specific_work: '',
        state_specific_home: '',
        signature_name: '',
        type: '',
        fee: 15.00,
        processingFee: 0.00,
        grand_total: 0.00,
        processingFeePercent: 3,
        seeking_prospective_first: '',
        seeking_prospective_second: '',
        resident_first: '',
        resident_second: '',
        driverlicense: '',
        driverlicense_extension: '',
        background_screening_type_id: 5
      };
      console.log('-----------');
    },
    getIpAddress() {
      axios.get('https://ipinfo.io/').then((res) => {
        this.IpAddressVal = res.data.ip;
        console.log(this.IpAddressVal);
      })
          .catch((error) => {
            console.log('Error:' + error);
            //this.step--;
            //this.button.nextButton = 'Next';
            return false;
            // error.response.status Check status code
          }).finally(() => {});
    },
    onlyNumber ($event) {
      //console.log($event.keyCode); //keyCodes value
      let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) { // 46 is dot
        $event.preventDefault();
      }
    },
    waiverAdditionalDataChange(event)
    {

      let state_rule_minnesota_check = document.getElementById("state_rule_minnesota_check");
      let state_rule_oklahoma_check = document.getElementById("state_rule_oklahoma_check");
      this.waiverSign[this.waiver_index].additional_data = {
        'state_rule_minnesota_check': state_rule_minnesota_check.checked,
        'state_rule_oklahoma_check': state_rule_oklahoma_check.checked
      };
    },
    downloadDocs(id) {
      this.waiverDownloading = true;
      axios.get(this.basePath + 'api/ncsi-custom/doc-download/'+id, {headers:this.memberHeaders, responseType: 'arraybuffer'})
          .then(response => {
            this.waiverDownloading = false;
            let blob = new Blob([response.data], { type: 'application/pdf' });
            let link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = 'NCSI-Documents'+moment().valueOf()+'.pdf';
            link.target = "_blank";
            link.click();
            link.remove();
          }).catch((error)=>{
            console.log("download error::", error);
        this.waiverDownloading = false;
      });
    },
    validateTelinput(obj){
      this.validTelinput = obj.valid;
      if(obj.valid){
        this.errors.phone = '';
      }
    },
    loadOtherPrices(){
      const retry2 = setInterval(() => {
        let other_pricing = this.getSiteProps('general.other_pricing');
        if(other_pricing !== undefined){
          axios.get(this.basePath+"api/other-pricing/current-prices")
              .then(res => {
                let data = res.data.data;
                if ( Object.prototype.hasOwnProperty.call(data, "mvr") ) {
                  this.fees.fee = parseFloat(data?.mvr || 0).toFixed(2);
                }
                this.feeCalculate();
              })
              .catch(err => {
                console.log(err);
              })
          clearInterval(retry2);
        }
      }, 200);
    },
    loadCurrentDocuments() {
      axios.get(this.basePath+"api/ncsi-custom/current-docs", { headers: this.memberHeaders })
          .then(res => {
            this.waiverTypeData = res.data.data;
            for(let i=0;i<this.waiverTypeData.length;i++) {
              let obj = {
                ncsi_document_id: null,
                slug: null,
                type: '',
                name: '',
                agree_first: '',
                agree_second: '',
                initials: '',
                ssn: '',
                additional_data: null
              };
              this.waiverSign.push(obj);
            }
          })
      }
  },
  beforeMount() {
    this.loadCurrentDocuments();
  },
  mounted() {
    this.loadOtherPrices();
    setTimeout(this.getStateClubs, 1000)
    this.cardYears();
    this.loadMembersInfo();
    const retry = setInterval(() => {
      this.props_fields = this.getSiteProps('memberRegistration.individual.fields');
      if(this.props_fields) clearInterval(retry);
    }, 200);
    //this.mountPropFooter();
    this.getIpAddress();
  }
};
</script>
<style scoped>
ul.listitems-ul {
    padding-left: 20px;
}
.ncsi-btn-selected{
  background: #05213e !important;
  border: 1px solid #05213e !important;
  color: #fff !important;
}
.mvr-wrap span.header-steps.completed {
  border-bottom: 4px solid #c8c8c8;
}
.mvr-wrap span.header-steps.current-item {
  border-bottom: 5px solid #d32f2e;
}
</style>
